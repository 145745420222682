/** 
 * @Desc: top-bar
 */
<template>
  <div id="topBarWrapper" class="top-bar-wrapper">
    <template v-if="!noAuthority && !serverError && component !== 'NotFound'">
      <div class="top-bar-content" :class="pageIsNumber ? 'h-88' : ''" @mouseenter="_headerEnter">
        <div class="top-bar-logo ">
          <img v-if="pageType === 'home'" class="img-home" src="../../assets/ui_ai-fe/logo3_300.jpg" alt="">
          <div class="top-bar-logo" v-else-if="!isPlayPage || menuIsOpen" @click="_goHome">
            <img class="img-home" src="../../assets/ui_ai-fe/logo3_300.jpg" alt="">
          </div>
        </div>

        <div class="top-bar-avatar ">
          <div v-if="isLogin" class="img-avatar cursor" @click.stop="drawerVisible = true"></div>
          <div v-else class="img-avatar-login cursor" @click.stop="_clickLogin"></div>
        </div>
      </div>
      <div v-if="isPlayPage && !menuIsOpen" class="left-back-box">
        <!-- <img
          class="img-go-back cursor"
          @click="_goBack"
          src="../../assets/new_ui/icon-arrow-left.png"
          alt=""
        >-->
        <div class="img-go-back cursor" @click="_goBack"></div>
      </div>
      <div v-if="pageIsNumber" :class="{
        'top-bar-animate': true,
        'top-bar-open': menuIsOpen,
        'top-bar-fold': !menuIsOpen
      }"></div>
    </template>
    <template v-else>
      <div class="top-bar-content">
        <div class="top-bar-logo " @click="_goHome">
          <img class="img-home cursor" src="../../assets/ui_ai-fe/logo3_300.jpg" alt="">
          <!-- <div class="img-to-home cursor" >
            1213
          </div> -->
        </div>
        <div class="top-bar-avatar">
          <div v-if="isLogin" class="img-avatar cursor" @click="drawerVisible = true"></div>
          <div v-else class="img-avatar-login cursor" @click="_clickLogin"></div>
        </div>
      </div>
    </template>
    <!-- 个人中心侧拉框 -->
    <el-drawer ref="avatarDrawer" custom-class="avatar-drawer" :visible.sync="drawerVisible" :modal-append-to-body="true"
      :append-to-body="true" :with-header="false">
      <span class="close-box" @click.stop="_closeDrawer">
        <img class="img-close" src="../../assets/new_ui/icon-drawer-close.png" alt="">
      </span>
      <div class="drawer-content">
        <div class="drawer-top" :class="isLogin ? 'drawer-top-login' : 'drawer-top-not-login'">
          <img v-if="isLogin" class="img-avatar float-left" src="../../assets/new_ui/avatar_header.png" alt="">
          <img v-else class="img-avatar cursor" src="../../assets/new_ui/avatar_header_gray.png" alt=""
            @click="_clickLogin">
          <span v-if="isLogin && accountInfo" class="span-name-login float-left">
            <span class="span-name no-wrap">{{ accountInfo.nickname }}</span>
            <br>
            <span class="span-phone">{{ accountInfo.phone | filterPhone }}</span>
          </span>
          <span v-else class="span-name cursor" @click="_clickLogin">登录</span>
        </div>
        <div v-if="isLogin" class="drawer-list">
          <div class="drawer-item" v-for="(item, index) in personalMenuList" :key="index" @click="_personalMenu(item)">
            <img class="img-icon" :src="item.icon_img" alt="">
            <span class="span-title"
              :style="{ borderBottom: item.title == '宝宝年龄' ? 'none' : '1px solid #f7eadb' }">{{ item.title }}</span>
          </div>
        </div>
        <div class="drawer-bottom login-btn" @click="_clickLogin" :style="{ background: isLogin ? '#E3FACD' : '#67B035' }">
          {{ isLogin ? '退出登录' : '登录/注册' }}</div>
      </div>
    </el-drawer>
    <!-- 选择年龄段 -->
    <AgeGroupDialog :visible="ageDialogVisible" @handleClick="_closeAgeDialog" />
    <!-- 登录 注册 找回密码 -->
    <LoginRegisterPwdDialog :visible="loginDialogVisible" component="login" @handleClick="_closeLoginDialog" />
  </div>
</template>
<script>
import bus from "utils/bus";
import store from "store";
import { mapState } from "vuex";
import AgeGroupDialog from "components/age-group-dialog";
import LoginRegisterPwdDialog from "components/login-register-pwd-dialog";
import { IsNumber, sessionStorageUtil } from "utils/util";
export default {
  components: {
    AgeGroupDialog,
    LoginRegisterPwdDialog
  },
  props: {
    component: {
      type: String
    }
  },
  data() {
    return {
      // menuList: [
      //   {
      //     pageType: "12156",
      //     title: "情商小达人",
      //     path: "/12156",
      //     module: "HOME",
      //     haveIndexOf: "/12156",
      //     icon_h: require("../../assets/new_ui/1_menu_h.png"),
      //     icon_v: require("../../assets/new_ui/1_menu_v_1.png")
      //   },
      //   {
      //     pageType: "12158",
      //     title: "神奇想象力",
      //     path: "/12158",
      //     module: "HOME",
      //     haveIndexOf: "/12158",
      //     icon_h: require("../../assets/new_ui/2_menu_h.png"),
      //     icon_v: require("../../assets/new_ui/2_menu_v_1.png")
      //   },
      //   {
      //     pageType: "12157",
      //     title: "小小科学家",
      //     path: "/12157",
      //     module: "HOME",
      //     haveIndexOf: "/12157",
      //     icon_h: require("../../assets/new_ui/3_menu_h.png"),
      //     icon_v: require("../../assets/new_ui/3_menu_v_1.png")
      //   },
      //   {
      //     pageType: "12155",
      //     title: "动物的秘密",
      //     path: "/12155",
      //     module: "HOME",
      //     haveIndexOf: "/12155",
      //     icon_h: require("../../assets/new_ui/4_menu_h.png"),
      //     icon_v: require("../../assets/new_ui/4_menu_v_1.png")
      //   },
      //   {
      //     pageType: "12159",
      //     title: "世界小公民",
      //     path: "/12159",
      //     module: "HOME",
      //     haveIndexOf: "/12159",
      //     icon_h: require("../../assets/new_ui/5_menu_h.png"),
      //     icon_v: require("../../assets/new_ui/5_menu_v_1.png")
      //   }
      // ],
      personalMenuList: [
        {
          title: "个人中心",
          path: "/personal-center/basic-info",
          module: "HOME",
          haveIndexOf: "/personal-center/basic-info",
          icon_img: require("../../assets/new_ui/icon-personal.png")
        },
        {
          title: "绘画记录",
          path: "/personal-center/painting-record",
          haveIndexOf: "/personal-center/painting-record",
          icon_img: require("../../assets/new_ui/icon-age-group.png")
        },
        {
          title: "修改密码",
          path: "/personal-center/change-pwd",
          haveIndexOf: "/personal-center/change-pwd",
          icon_img: require("../../assets/new_ui/icon-pwd.png")
        }
        // {
        //   title: "最近播放",
        //   path: "/personal-center/history-record",
        //   module: "HOME",
        //   haveIndexOf: "/personal-center/history-record",
        //   icon_img: require("../../assets/new_ui/icon-history.png")
        // },
        // {
        //   title: "我的收藏",
        //   path: "/personal-center/collection",
        //   module: "HOME",
        //   haveIndexOf: "/personal-center/collection",
        //   icon_img: require("../../assets/new_ui/icon-collection.png")
        // },
        // {
        //   title: "宝宝年龄",
        //   path: "",
        //   module: "HOME",
        //   haveIndexOf: "",
        //   icon_img: require("../../assets/new_ui/icon-age-group.png")
        // }
      ],
      isSelect: null,
      pageType: null,
      // pageIsNumber: false,
      pageIsNumber: true,
      isPlayPage: false,
      // menuIsOpen: false,
      menuIsOpen: true,
      drawerVisible: false,
      ageDialogVisible: false,
      loginDialogVisible: false,
      timer: null
      // isLogin: true,
      // accountInfo: {
      //   userName: '测试账号',
      //   phone: '15700001234'
      // }
    };
  },
  watch: {
    menuIsOpen: {
      immediate: true,
      handler(val, oldVal) {
        // console.log(val, oldVal);
      }
    }
  },
  computed: {
    ...mapState(["accountInfo", "isLogin", "noAuthority", "serverError"]),
    selectType() {
      let i = this.menuList.findIndex(ele => ele.pageType == this.pageType);
      if (i == -1) {
        i = 0;
      }
      return this.menuList[i].icon_v;
    }
  },
  filters: {
    filterPhone(val) {
      if (val) {
        let str = val.toString();
        let prev = str.substr(0, 3);
        let next = str.substr(7, 4);
        return prev + "****" + next;
      }
    }
  },
  methods: {
    _goHome() {
      let { path } = this.$route;
      if (path === "/home-page") {
        bus.$emit("Reload");
      } else {
        this.$router.push("/home-page");
      }
    },
    /**
     * pageType发生改变时
     * **/
    _pageType(val, last) {
      this.pageIsNumber = IsNumber(val);
      this.pageType = val;
      let _l = this.$route.path.split("/");
      if (_l[2] && _l[2] === "play") {
        this.isPlayPage = true;
      } else {
        this.isPlayPage = false;
      }
      clearTimeout(this.timer);
      this.timer = null;

      let pathData = ["/12156", "/12158", "/12157", "/12155", "/12159"];
      // 判断为 pathData 中的路径时，显示顶部导航
      if (pathData.includes(this.$route.path)) {
        this.menuIsOpen = true;
      } else {
        this.menuIsOpen = false;
      }

      // if (sessionStorageUtil.getItem("fromType") === "home") {
      //   // sessionStorageUtil.removeItem("fromType");
      //   this.menuIsOpen = true;
      //   this.timer = setTimeout(() => {
      //     this.menuIsOpen = false;
      //     clearTimeout(this.timer);
      //     this.timer = null;
      //   }, 3000);
      // } else {
      //   this.menuIsOpen = false;
      // }
    },
    /**
     * 鼠标移入header时 取消定时器
     * **/
    _headerEnter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    /**
     * 点击图片跳转页面
     * **/
    routerChange(item, index) {
      this.isSelect = index;
      this.$router.push(item.path);
      console.log(item, this.isSelect, "1212");
    },
    /**
     * 展开 折叠 change
     * **/
    _openChange() {
      // this.menuIsOpen = !this.menuIsOpen;
    },

    /**
     * 返回首页
     * **/
    _goBack() {
      let _path = this.$route.path.replace("/play", "");
      let fromName = this.$route.query.fromName;
      if (fromName === "Collection") {
        _path = "/personal-center/collection";
      } else if (fromName === "HistoryRecord") {
        _path = "/personal-center/history-record";
      }
      this.$router.push(_path);
    },
    /**
     * 点击头像
     * **/
    _personalMenu(item) {
      this._closeDrawer();
      if (item.path) {
        this.$router.push(item.path);
      } else {
        this.ageDialogVisible = false;
        setTimeout(() => {
          this.ageDialogVisible = true;
        }, 200);
      }
    },

    /**
     * 关闭右侧个人中心的抽屉
     * **/
    _closeDrawer() {
      this.$refs.avatarDrawer.hide();
    },
    _clickLogin() {
      if (this.isLogin) {
        // 退出登录
        store.commit("LOGOUT", {});
        store.dispatch("setLogout");
      } else {
        this.loginDialogVisible = false;
        setTimeout(() => {
          this.loginDialogVisible = true;
        }, 200);
      }
      this._closeDrawer();
    },

    /**
     * 关闭选择年龄的弹框
     * **/
    _closeAgeDialog() {
      this.ageDialogVisible = false;
    },
    /**
     * 关闭登录
     * **/
    _closeLoginDialog(type) {
      this.loginDialogVisible = false;
    }
  },
  created() { },
  destroyed() {
    clearTimeout(this.timer);
  }
};
</script>
<style lang="less">
.top-bar-wrapper {
  width: 100%;
  height: 10vh;
  padding: 0 0.4rem;
  // background: #fffaf1;
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
  text-align: center;
  // box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);

  .top-bar-content {
    height: 88px;
    display: flex;
    justify-content: center;
    position: relative;

    &.h-88 {
      height: 88px;
    }

    .top-bar-logo {
      // margin-top: 24px;
      position: absolute;
      left: 0;
      cursor: pointer;

      .img-home {
        // width: 84px;
        // height: 50px;
        // width: 2.60rem;
        width: auto;
        height: 0.59rem;
        // margin-left: 0.1rem;
        margin-top: 0.29rem;
      }

      .img-to-home {
        // height: 40px;
        margin-top: 0.2rem;
        // width: 70px;
        // height: 70px;

        width: auto;
        height: 0.59rem;
        // animation: logo_fadeIn 0.5s;
        //background: url("../../assets/logo.png") no-repeat;
        //background-size: 100% 100%;
        // &:hover {
        //   // filter: saturate(125%) brightness(108%);
        //   background: url("../../assets/new_ui/logo_home_hover.png") no-repeat;
        //   background-size: 100% 100%;
        // }
      }
    }

    .menu-center {
      display: inline-block;
      position: absolute;
      left: 50%;
      margin-left: -80px;
      background-position: center bottom;
      transform-style: preserve-3d;

      &.menu-center-fold {
        width: 180px;
        height: 110px;
        bottom: -28px;

        animation: flipClose 0.4s linear forwards;

        .img-menu {
          cursor: pointer;
          position: absolute;
          text-align: center;
          left: 50%;
          margin-left: -90px;
          bottom: 4px;
          // transition: opacity .4s ease-in;
          // backface-visibility: hidden;
        }
      }

      &.menu-center-open {
        width: 180px;
        height: 70px;
        overflow: hidden;
        bottom: -54px;

        animation: flipOpen 0.4s linear forwards;

        .img-menu {
          cursor: pointer;
          position: absolute;
          text-align: center;
          left: 50%;
          margin-left: -85px;
          bottom: 8px;

          // backface-visibility: visible;
          // transition: opacity .3s ease-in;
        }
      }
    }

    .top-bar-avatar {
      margin-top: 0.29rem;
      position: absolute;
      right: 0;
      width: 0.7rem;
      height: 0.7rem;

      .img-avatar {
        // width: 50px;
        // height: 50px;
        width: 0.7rem;
        height: 0.7rem;
        background: url("../../assets/new_ui/avatar_header.png") no-repeat;
        background-size: 100% 100%;

        &:hover {
          // filter: saturate(125%) brightness(108%);
          background: url("../../assets/new_ui/avatar_header_hover.png") no-repeat;
          background-size: 100% 100%;
        }
      }

      .img-avatar-login {
        width: 0.7rem;
        height: 0.7rem;
        background: url("../../assets/new_ui/avatar_header_gray.png") no-repeat;
        background-size: 100% 100%;

        &:hover {
          filter: saturate(125%) brightness(108%);
          // background: url("../../assets/new_ui/avatar_header_hover.png")
          //   no-repeat;
          // background-size: 100% 100%;
        }
      }
    }

    .top-menu-box {
      display: inline-block;
      // margin-top: 26.5px;
      margin-top: 10px;
      margin-right: 5px;
      position: relative;

      // left: -100px;
      .menu-item {
        display: inline-block;
        opacity: 0;

        &:nth-of-type(1) {
          animation: bounceIn 0.5s linear 0.4s forwards;
        }

        &:nth-of-type(2) {
          animation: bounceIn 0.5s linear 0.4s forwards;
        }

        &:nth-of-type(3) {
          animation: bounceIn 0.5s linear 0.4s forwards;
        }

        &:nth-of-type(4) {
          animation: bounceIn 0.5s linear 0.4s forwards;
        }

        &:nth-of-type(5) {
          animation: bounceIn 0.5s linear 0.4s forwards;
        }

        &+.menu-item {
          margin-left: 0.8rem;
        }

        img {
          // width: 159px;
          width: 1.6rem;
        }

        .img-menu:hover {
          // animation: rubberBand 0.4s linear;
          animation-name: tada;
          transform-origin: center bottom;
          animation-duration: 0.5s;
          animation-fill-mode: both;
        }
      }
    }
  }

  .left-back-box {
    margin-top: 0.17rem;
    width: 0.7rem;
    height: 0.7rem;
    position: absolute;
    left: 0.4rem;
    // bottom: 23px;
    top: 0.03rem;

    .img-go-back {
      width: 100%;
      height: 100%;
      // animation: logo_fadeIn 0.5s;
      background: url("../../assets/new_ui/icon-arrow-left.png") no-repeat;
      background-size: 100% 100%;

      &:hover {
        // filter: saturate(125%) brightness(108%);
        background: url("../../assets/new_ui/icon-arrow-left-hover.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .top-bar-animate {
    width: 100%;
    height: 0;
  }

  .top-bar-open {
    height: 40px;
    animation: headerOpen 0.8s;
  }

  .top-bar-fold {
    height: 0px;
    animation: headerClose 0.8s;
  }

  @keyframes flipOpen {
    0% {
      transform: perspective(400px) rotateX(90deg);
    }

    40% {
      transform: perspective(400px) rotateX(-10deg);
    }

    70% {
      transform: perspective(400px) rotateX(10deg);
    }

    100% {
      transform: perspective(400px) rotateX(0);
    }
  }

  @keyframes flipClose {
    0% {
      transform: perspective(400px) rotateX(-90deg);
    }

    40% {
      transform: perspective(400px) rotateX(10deg);
    }

    70% {
      transform: perspective(400px) rotateX(-10deg);
    }

    100% {
      transform: perspective(400px) rotateX(0);
    }
  }
}

.el-drawer__wrapper {
  .avatar-drawer {
    overflow: visible;
    width: 3rem !important;
    border-radius: 40px 0 0 40px;

    .el-drawer__body {
      overflow: visible;
      position: relative;

      .close-box {
        display: inline-block;

        width: 0.6rem;
        height: 0.6rem;
        // border-radius: 40px;
        // background-color: #ffffff;
        position: absolute;
        left: -45px;
        top: 45%;
        margin-top: -0.14rem;
        cursor: pointer;

        .img-close {
          position: absolute;
          left: 6px;
          top: 15px;
          // width: 100%;
          // height: 100%;
        }

        &:hover {
          .img-close {
            filter: saturate(125%) brightness(108%);
          }
        }
      }

      .drawer-content {
        position: relative;
        height: 100vh;
        border-radius: 40px 0 0 40px;

        .drawer-top {
          width: 100%;
          // height: 140px;
          // line-height: 64px;
          // padding: 42px 0px 42px 54px;

          height: 1.4rem;
          line-height: 0.64rem;
          padding: 0.42rem 0px 0.42rem 0.54rem;

          border-radius: 50px 0 0 0;
          border-radius: 40px 0 0 0px;

          .img-avatar {
            display: inline-block;
            vertical-align: middle;

            width: 0.64rem;
            height: 0.64rem;
          }

          .span-name {
            display: inline-block;
            vertical-align: middle;
            margin-left: 0.1rem;
          }

          &.drawer-top-login {
            background: #ade787;

            .span-name-login {
              display: inline-block;
              line-height: 0.01rem;
              margin-top: 0.06rem;

              .span-name {
                display: inline-block;
                vertical-align: middle;
                line-height: 0.24rem;
                color: #448816;
                // font-size: 18px;
                font-size: 0.18rem;
                font-weight: 600;
                text-align: left;
                // width: 126px;
                width: 1.26rem;
                font-family: PingFangSC-Semibold, PingFang SC !important;
              }

              .span-phone {
                display: inline-block;
                vertical-align: middle;
                line-height: 0.24rem;

                color: #448816;
                font-size: 0.15rem;
                text-align: left;
                margin-left: 0.1rem;
                font-family: PingFangSC-Semibold, PingFang SC !important;
              }
            }
          }

          &.drawer-top-not-login {
            background: #f0f0f0;

            .span-name {
              color: #737373;
              font-size: 0.16rem;
              font-weight: bold;
            }
          }
        }

        .drawer-list {
          padding: 0 0.1rem;
          margin-top: 0.16rem;
          position: relative;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: space-between;
          height: 16vh;

          .drawer-item {
            height: 3vh;
            width: 100%;
            // line-height: 0.35rem;
            // line-height: 4vh;
            cursor: pointer;
            padding: 0 0.1rem;
            // text-align: left;
            display: flex;
            align-items: center;

            .img-icon {
              width: 0.34rem;
              height: 0.26rem;
              display: inline-block;
              vertical-align: middle;
            }

            .span-title {
              display: inline-block;
              vertical-align: middle;
              width: 80%;
              height: 3vh;
              line-height: 3vh;
              font-size: 0.16rem;
              font-weight: bold;
              color: #448816;
              margin-left: 0.15rem;
              border-bottom: 0.01rem solid #f7eadb;
            }

            &:hover {

              .img-icon,
              .span-title {
                opacity: 0.8;
              }
            }

            &:not(:last-of-type) {
              // border-bottom: 1px solid #f7eadb;
            }
          }
        }

        .drawer-bottom {
          position: absolute;
          bottom: 0;
          left: 0.54rem;
          // bottom: 0.42rem;
          top: 85vh;
          margin: 0 auto;
          width: 2rem !important;
          height: 0.46rem !important;
          line-height: 0.46rem !important;
          text-align: center;
          font-size: 0.16rem !important;
        }

        .login-btn {
          width: 200px;
          height: 46px;
          line-height: 46px;
          text-align: center;
          font-size: 16px;

          &:hover {
            background: #d0f1b0 !important;
          }
        }
      }
    }
  }
}

.img-menu1 {
  // margin-top: 100px;
  position: relative;
  top: 0.16rem;
  animation: none 0 ease 0 1 normal;
  animation-name: none;
  transform-origin: center bottom;
  animation-duration: 0s;
  animation-fill-mode: none;
}

.select-menu {
  img:hover {}
}
</style>